import { COUNTRIES_LIST } from "./constants";

export function getCountryById(id) {
  let r;
  if (id && id.length === 3) {
    r = COUNTRIES_LIST.find(e => e.code3 === id);
  } else if (id && id.length === 2) {
    r = COUNTRIES_LIST.find(e => e.code === id);
  }
  return r && r.name;
}
