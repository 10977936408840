import React from "react";
import "../../styles/components/loader.scss";
function Loader() {
  return (
    <div id="spin">
      <svg className="progress" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="42" />
      </svg>
    </div>
  );
}

export default Loader;
