import React, { useEffect, useState, useContext } from "react";
import { navigate } from "gatsby";
import { Table, Button, Row, Col, message, Modal } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import * as Moment from "moment";
import { fetchUsers, eraseUser } from "../../utils/api";
import { SEO, Layout } from "../../components";
import { getCountryById } from "../../utils/getCountryById";
import { AuthStateContext } from "../../context/AuthContextProvider";
import { getCookie } from "../../utils/getCookie";
import { eraseAllCookies } from "../../utils/eraseAllCookies";
import { ENDPOINTS } from "../../utils/constants";
import "../../styles/dashboard.scss";
import Loader from "../../components/common/Loader";

const UserRow = ({ token, user, onUsersChange }: any) => {
  const [erasingUser, setErasingUser] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <Row gutter={20}>
      <Col>
        <Button
          shape="circle"
          style={{ background: "#F1F2EA" }}
          icon={<EditOutlined />}
          onClick={() => {
            navigate(`/dashboard/edit?id=${user.id}`);
          }}
        />
      </Col>
      <Col>
        <Button
          shape="circle"
          style={{ background: "#F1F2EA" }}
          icon={<DeleteOutlined />}
          onClick={() => {
            // console.log("lick");
            setModalVisible(true);
          }}
          loading={erasingUser}
        />
        <Modal
          className="delete-confirmation"
          title="Confirmation"
          centered
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          onOk={() => {
            setErasingUser(true);
            eraseUser({ token, userId: user.id })
              .then(r => {
                if (r.ok) {
                  onUsersChange(o => o.filter(u => u.id !== user.id));
                }
                setModalVisible(false);
              })
              .catch(err => {
                console.log(err);
                setErasingUser(false);
              });
          }}
          footer={[
            <Button key="back" onClick={() => setModalVisible(false)}>
              No
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setErasingUser(true);
                eraseUser({ token, userId: user.id })
                  .then(r => {
                    if (r.ok) {
                      onUsersChange(o => o.filter(u => u.id !== user.id));
                      setModalVisible(false);
                    }
                  })
                  .finally(() => {
                    setErasingUser(false);
                  });
              }}
            >
              Yes
            </Button>,
          ]}
        >
          <div className="d-flex align-items-center">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                marginRight: 10,
                marginTop: 5,
                flexShrink: 0,
                alignSelf: "baseline",
              }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 0C3.13359 0 0 3.13359 0 7C0 10.8664 3.13359 14 7 14C10.8664 14 14 10.8664 14 7C14 3.13359 10.8664 0 7 0ZM7.4375 10.9375C7.4375 11.1795 7.24199 11.375 7 11.375C6.75801 11.375 6.5625 11.1795 6.5625 10.9375C6.5625 10.6955 6.75801 10.5 7 10.5C7.24199 10.5 7.4375 10.6955 7.4375 10.9375ZM7.4375 9.1875C7.4375 9.42949 7.24199 9.625 7 9.625C6.75801 9.625 6.5625 9.42949 6.5625 9.1875V3.0625C6.5625 2.82051 6.75801 2.625 7 2.625C7.24199 2.625 7.4375 2.82051 7.4375 3.0625V9.1875Z"
                fill="#FAAD14"
              />
            </svg>
            <div>
              Are you sure you want to erase &rdquo;
              <span className="capitalized">{user.title}</span>
              {user.fname && (
                <span className="capitalized">{` ${user.fname}`}</span>
              )}
              {user.lname && (
                <span className="capitalized">{` ${user.lname}`}</span>
              )}
              &rdquo;?
            </div>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

export default function Dashboard() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { accessToken } = useContext(AuthStateContext);

  const token = getCookie("accessToken") || accessToken;

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      fetchUsers(token)
        .then(async r => {
          if (r.status === 401) {
            eraseAllCookies();
            navigate("/");
          }
          if (r.ok) {
            const u = await r.json();
            setUsers(u.users);
          } else {
            setError(true);
          }
        })
        .catch(setError)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [token]);
  return (
    <Layout>
      <SEO title="Home" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 100px)",
        }}
      >
        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : error ? (
          <div>unkown error</div>
        ) : (
          <div
            style={{ maxWidth: "700px", width: "100%" }}
            className="dashboard__table"
          >
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: 30 }}
            >
              <div className="dashboard__request">Request</div>
              <Button
                className="dashboard__clear-db"
                onClick={() =>
                  fetch(`${ENDPOINTS.USERS}/clear-db`, {
                    method: "get",
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                    .then(r => {
                      if (r.ok) {
                        setUsers([]);
                        message.success("success");
                      } else message.error("some unkown error happened");
                    })
                    .catch(e => {
                      console.log(e);
                      message.error("some unkown error happened");
                    })
                }
              >
                Clear DB
              </Button>
            </Row>
            <Table
              columns={[
                {
                  title: "Creation date",
                  dataIndex: "createdAt",
                  key: "createdAt",
                  render: v => Moment(v).format("DD/MM/YYYY"),
                },
                { title: "Title", dataIndex: "title", key: "title" },
                { title: "First Name", dataIndex: "fname", key: "fname" },
                { title: "Last Name", dataIndex: "lname", key: "lname" },
                {
                  title: "Country",
                  dataIndex: "countryOfResidence",
                  key: "countryOfResidence",
                  render: v => getCountryById(v),
                },
                {
                  title: "Actions",
                  dataIndex: "action",
                  key: "action",
                  render: (v, user) => {
                    return (
                      <UserRow
                        token={token}
                        onUsersChange={setUsers}
                        user={user}
                      />
                    );
                  },
                },
              ]}
              dataSource={users}
              rowKey="id"
            />
          </div>
        )}
        {/* <Link to="/">I wanna register</Link> */}
      </div>
      {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
    <Image />
  </div> */}
    </Layout>
  );
}
